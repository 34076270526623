import React from "react";
import hero from "../assets/hero.webp";
import herotwo from "../assets/hero2.webp";
const Home = () => {
  return (
    <main className="bg-[#ffcc33]">
      <section className="w-full min-h-auto ">
        <img src={hero} alt="hero" className="w-full" />
      </section>
      <section className="w-full min-h-[100vh] flex flex-col align-middle justify-center items-center bg-white pb-10">
        <h1 className="text-[155px] text-[#ffcc33] font-Anton">STAX</h1>
        <p className="w-[80vw] font-Agenda font-semibold text-center">
          Fill your little lady's closet with our exclusive line of adolescent
          dresses at R&B. With the largest selection of children's dresses, you
          may discover everything your daughter needs, from adorable adolescent
          dresses and festive ruffle dresses to simple everyday wear dresses and
          effortlessly put together appearances.
        </p>
      </section>
      <section className="w-full flex flex-col align-middle justify-between items-center relative">
        <img src={herotwo} alt="hero" className="w-full top-0 m-0 p-o " />
        <div className="bg-[#ffcc33] w-full p-10 flex align-middle justify-center items-center  bottom-0">
          <p className="w-[80vw] font-Agenda font-semibold text-center  ">
            We have a wide selection of girls' dresses to fit all seasons and
            events, whether you're searching for semi-formal dresses for
            teenagers or formal dresses for a special occasion. Girls' gowns
            come in sizes to fit girls as young as five years old and young
            women as old as fifteen.
          </p>
        </div>
      </section>
    </main>
  );
};

export default Home;
