import girl1 from "../assets/girl.png";
import girl2 from "../assets/girl2.png";

const About = () => {
  return (
    <section className="w-full min-h-[100vh] flex flex-col align-middle justify-center items-center reative pt-20">
      <h1 className="text-[155px] text-[#ffcc33] font-Anton">ABOUT</h1>
      <p className="w-[50vw] font-Agenda font-semibold text-center ">
        We are a sophisticated destination for fashion and lifestyle that is
        dedicated to dressing youngsters between the ages of five and fifteen.
        We bring stylish shoes, purses, clothes, makeup products, and
        accessories straight to your doorstep. We provide to you our branded
        clothing at the most competitive costs since we think that looking
        expensive shouldn't come at the expense of quality.
      </p>
      <img
        src={girl2}
        alt="girl"
        className="absolute right-0 top-[20%] lg:visible md:visible invisible"
      />
      <div className="relative w-full flex alighn-midle justify-center items-center">
        <img
          src={girl1}
          alt="girl"
          className="absolute left-5 lg:visible md:visible invisible"
        />
        <p className="w-[50vw] font-Agenda font-semibold text-center mt-[20%] mb-[20%]">
          The most visible benefit of Stax has been the enhanced design and
          stimulating colour experience for kids. Stax offers the same great
          value for money in a visually exciting way. It is shaping consumer
          perceptions, increasing brand worth and fuelling future growth for the
          brand in the mid-market retail section.
        </p>
      </div>
    </section>
  );
};

export default About;
