import logo from "../assets/logo.png";
import { NavLink, Link } from "react-router-dom";
const Navbar = () => {
  return (
    <nav className="flex bg-[#ffcc33]  flex-row align-middle justify-between h-[110px] overflow-hidden w-[100vw] shadow-lg shadow-slate-500 z-10">
      <div className=" max-w-full flex pr-3  lg:pl-5  w-1/3 align-middle justify-start items-center">
        <Link to="/">
          {" "}
          <img
            src={logo}
            alt="logo"
            className="max-h-[50px] font-WixMade lg:max-h-[60px] md:max-h-[45px] pl-10  flex align-middle justify-evenly"
          />
        </Link>
      </div>
      <div className=" max-w-full w-2/3 lg:w-1/2 sm:w-1/2 flex flex-row align-middle justify-evenly pr-5 items-center">
        <NavLink
          className={({ isActive }) => {
            return isActive
              ? "text-white p-2 items-center font-WixMade  text-[13px] lg:text-sm sm:text-sm"
              : "text-black pl-2 items-center font-WixMade   text-[13px] lg:text-sm sm:text-sm";
          }}
          to="/"
        >
          Home
        </NavLink>
        <NavLink
          className={({ isActive }) => {
            return isActive
              ? "text-white p-2 items-center font-WixMade  text-[13px] lg:text-sm sm:text-sm"
              : "text-black pl-2 items-center font-WixMade   text-[13px] lg:text-sm sm:text-sm";
          }}
          to="/product"
        >
          Product
        </NavLink>
        <NavLink
          className={({ isActive }) => {
            return isActive
              ? "text-white p-2 items-center font-WixMade text-[13px] lg:text-sm sm:text-sm"
              : "text-black pl-2 items-center  font-WixMade   text-[13px] lg:text-sm sm:text-sm";
          }}
          to="/about"
        >
          About
        </NavLink>
        <NavLink
          className={({ isActive }) => {
            return isActive
              ? "text-white p-2 items-center font-WixMade text-[13px] lg:text-sm sm:text-sm"
              : "text-black pl-2 items-center font-WixMade  text-[13px] lg:text-sm sm:text-sm";
          }}
          to="/contact"
        >
          Contact
        </NavLink>
      </div>
    </nav>
  );
};

export default Navbar;
