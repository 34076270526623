import product_img from "../assets/product.webp";
import ProductCard from "../components/ProductCard";
import { products } from "../db//db.js";
const Product = () => {
  return (
    <main>
      <section className="w-full min-h-auto flex flex-col align-middle justify-center items-center reative">
        <img src={product_img} alt="girl" className=" w-full" />
        <h1 className="lg:text-[155px] md:text-[80px] sm:text-[75px] text-[50px] text-[#ffcc33] font-Anton pt-16">
          PRODUCT
        </h1>
        <p className="w-[50vw] text-2xl font-Agenda  text-center ">
          AGE BETWEEN 5-15
        </p>
      </section>
      <section className="w-full min-h-[100vh] bg-[#ffcc33] flex flex-col align-middle justify-center items-center reative">
        <div className="flex flex-row max-w-[100vw] p-10 flex-wrap align-middle justify-evenly ">
          <div className=" w-full lg:w-1/3 flex flex-col align-middle justify-center items-center">
            <h1 className="text-xl lg:text-2xl mb-5 lg:mb-10">
              Boy&apos;s Wardrobe
            </h1>
            <div className="flex flex-row flex-wrap gap-10 items-center justify-center align-middle">
              {products[0].boyswardrobe.map((e) => {
                return (
                  <div key={e.id}>
                    <ProductCard card={e} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className=" w-full lg:w-1/3 flex flex-col align-middle justify-center items-center">
            <h1 className="text-xl lg:text-2xl mb-5 lg:mb-10">
              Girl&apos;s Wardrobe
            </h1>
            <div className="flex flex-row flex-wrap gap-10 items-center justify-center align-middle">
              {products[0].girlswardrobe.map((e) => {
                return (
                  <div key={e.id}>
                    <ProductCard card={e} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Product;
